<template>
  <div class="verification-wrap">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
      <el-form-item prop="phonenumber">
        <span class="first-tip">首次登录会自动创建新账号</span>
        <el-input
          ref="phonenumber"
          clearable
          v-model="loginForm.phonenumber"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="verification">
        <el-input
          placeholder="请输入验证码"
          ref="verification"
          v-model="loginForm.verification"
        >
          <el-button
            class="verification-btn"
            type="primary"
            slot="append"
            :disabled="verifibtn"
            @click="getverificate"
            >{{ vervalue ? vervalue : "获取验证码" }}</el-button
          >
        </el-input>
      </el-form-item>
      <el-button
        :loading="loading"
        class="loginbtn"
        type="primary"
        @click.native.prevent="handleLogin"
        native-type="submit"
        >登录</el-button
      >
      <el-checkbox class="checkagree" v-model="agreement"></el-checkbox>
      <span class="useragree">我已阅读并同意</span>
      <span @click="navUserAgreement" class="protocal">《用户协议》</span>
      <span class="useragree">和</span>
      <span @click="navPrivacyPolicy" class="protocal">《隐私政策》</span>
      <div class="otherlogin">
        <div @click="loginwx" class="weixinlogin">
          <i class="iconfont icon-weixin"></i
          ><span class="wxtext">微信登录</span>
        </div>
        <!--<div class="qqlogin">
          <i class="iconfont icon-qq"></i><span class="qqtext">QQ登录</span>
        </div>-->
      </div>
    </el-form>
  </div>
</template>
<script>
import { isPhonenumber } from "../../../utils/validate";
import { userAgreement, privacyPolicy } from "@/utils/public";
import { Message } from "element-ui";
export default {
  data() {
    const validatePhonenumber = (rule, value, callback) => {
      if (!isPhonenumber(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validateVerifica = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        phonenumber: "",
        verification: "",
      },
      loginRules: {
        phonenumber: [
          { required: true, trigger: "blur", validator: validatePhonenumber },
        ],
        verification: [
          { required: true, trigger: "blur", validator: validateVerifica },
        ],
      },
      loading: false,
      vervalue: "",
      verifibtn: false,
      agreement: true,
    };
  },
  props: ["phoneNumber"],
  watch: {
    phoneNumber(val) {
      this.loginForm.phonenumber = val;
      this.setFocus();
    },
  },
  mounted() {
    //console.log("received phone number: " + this.phoneNumber);
    if (this.phoneNumber != "") {
      this.loginForm.phonenumber = this.phoneNumber;
    }
    this.setFocus();
  },
  methods: {
    handleLogin() {
      if (!this.agreement) {
        this.$message.warning("需同意用户协议和隐私政策才能登录");
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        console.log("referer: " + document.referrer);
        if (valid) {
          this.loading = true;
          const data = {
            Type: 1,
            PhoneNumber: this.loginForm.phonenumber,
            VerificationCode: this.loginForm.verification,
            Referrer: document.referrer,
          };
          this.$store
            .dispatch("user/login", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$router.push({
                  path: "/",
                });
              }
              if (res.RetCode == 5) {
                this.$message.error("验证码错误");
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getverificate() {
      const valid = this.$refs.phonenumber.validateState;
      if (valid == "success") {
        this.verifibtn = true;
        var vartime = 60;
        var timer = setInterval(() => {
          vartime--;
          this.vervalue = `${vartime}s`;
          if (vartime == 0) {
            clearInterval(timer);
            this.verifibtn = false;
            this.vervalue = "";
          }
        }, 1000);
        var payload = {
          PhoneNumber: this.loginForm.phonenumber,
        };
        this.$store.dispatch("user/sendvertification", { ...payload });
        // TODO  发送成功后调用
        this.$message({
          showClose: true,
          message: "验证码发送成功",
          showClose: false,
          type: "success",
        });
      } else {
        return;
      }
    },
    loginwx() {
      if (!this.agreement) {
        this.$message.warning("需同意用户协议和隐私政策才能登录");
        return;
      }
      window.location.href = `
      https://open.weixin.qq.com/connect/qrconnect
			?appid=wx70dd623f59efd16f
			&redirect_uri=${encodeURIComponent("https://console.wumitech.com/#/login")}
			&response_type=code
			&scope=snsapi_login&state=${Math.ceil(Math.random() * 1000)}
			`;
    },
    setFocus() {
      if (this.loginForm.phonenumber === "") {
        this.$refs.phonenumber.focus();
      } else if (this.loginForm.verification === "") {
        this.$refs.verification.focus();
      }
    },
    navUserAgreement() {
      window.location.href = userAgreement;
    },
    navPrivacyPolicy() {
      window.location.href = privacyPolicy;
    },
  },
};
</script>
<style lang="scss" scoped>
.verification-wrap {
  margin-top: 35px;
  .first-tip {
    color: #777777;
    font-size: 10px;
  }
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-input__inner {
    border-radius: 5px;
  }
  ::v-deep .el-input-group__append {
    border: none;
  }
  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    background: rgba(88, 151, 242, 1);
  }
  .verification-btn {
    width: 100px;
    padding-left: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgba(88, 151, 242, 1);
  }
  .checkagree {
    margin-bottom: 10px;
    color: rgba(61, 61, 61, 1);
  }
  .useragree {
    margin-left: 5px;
    font-size: 12px;
  }
  .protocal {
    font-size: 12px;
    margin-left: 2px;
    color: rgba(74, 99, 240, 0.93);
    cursor: pointer;
  }
  .otherlogin {
    display: flex;
    height: 50px;
    margin-top: 10px;
    justify-content: space-around;
    .weixinlogin {
      cursor: pointer;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
    }
    .qqlogin {
      cursor: pointer;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
    }
    .wxtext {
      margin-left: 10px;
    }
    .qqtext {
      margin-left: 10px;
    }
    .icon-weixin {
      font-size: 16px;
      color: #28c445;
    }
    .icon-qq {
      font-size: 16px;
      color: #4da0ff;
    }
  }
}
</style>